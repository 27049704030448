$primary: #f69044; // bn-orange

$link-decoration: none;

$grid-gutter-width: 1.875rem;

// ignore the xxl breakpoint for now
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

$progress-border-radius: 10rem;
$progress-height: 1.2rem;

$dropdown-font-size: 0.875rem;
$dropdown-bg: var(--bn-dark-blue);

$legend-font-size: 1rem; // override default

// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/containers"; // deprecated
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/forms";

// Note: required by the close buttons on most of the 
// pop-up throughout the site
@import "~bootstrap/scss/close"; // deprecated


// Utilities
@import "~bootstrap/scss/utilities/api";
